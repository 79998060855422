@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("tldraw/tldraw.css");

body {
  height: 100%;
}

html {
  height: 99vh;
}